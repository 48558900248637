/* input fields */
.valdHsFormBlock .hs-form {
  padding-top: 3rem;
}
.valdHsFormBlock fieldset {
  max-width: 100% !important;
  width: 100% !important;
  padding-top: 8px !important;
}

.valdHsFormBlock .form-columns-2 {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.valdHsFormBlock .form-columns-2 div {
  width: 100% !important;
}

.valdHsFormBlock .form-columns-1 {
  display: flex;
  flex-direction: column;
}

.valdHsFormBlock .hs-fieldtype-text .input,
.valdHsFormBlock .hs-fieldtype-select .input,
.valdHsFormBlock .hs-mobilephone .input {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 5px;
}

.valdHsFormBlock .hs-fieldtype-text input,
.valdHsFormBlock .hs-fieldtype-select input,
.valdHsFormBlock .hs-mobilephone input {
  width: 100% !important;
}

.valdHsFormBlock .hs-fieldtype-text label,
.valdHsFormBlock .hs-fieldtype-select label,
.valdHsFormBlock .hs-mobilephone label {
  color: #25282a;
  font-size: 16px;
  font-weight: 400;
}

.valdHsFormBlock fieldset div {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.valdHsFormBlock .hs-fieldtype-text input,
.valdHsFormBlock .hs-fieldtype-phonenumber input {
  padding: 15px;
  background: transparent;
  color: #25282a;
  font-size: 16px;
}

.valdHsFormBlock .hs-fieldtype-text input::placeholder,
.valdHsFormBlock .hs-fieldtype-phonenumber input::placeholder,
.valdHsFormBlock .hs-dependent-field .is-placeholder {
  font-size: 16px;
  color: #a2a2a2;
}

.valdHsFormBlock .inputs-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.valdHsFormBlock .hs-dependent-field select {
  background-color: #f6f6f6 !important;
  padding: 15px 15px 15px 10px;
  border-radius: 5px;
  font-size: 16px;
  color: #25282a;
  width: 100% !important;

  /* dropdown icon */
  background: url(../../public/static/imgs/dropdown-icon.png) no-repeat right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position-x: 98%;
  background-size: 12px;
}

.valdHsFormBlock .hs-error-msgs label,
.bdm-popup .valdHsFormBlock .hs-error-msgs label {
  color: #ce4956;
  font-size: 12px;
  margin-bottom: 1rem;
  margin-left: 7px !important;
  font-weight: 200;
}

/* Section: I am interested in , Vald Newsletter */

.valdHsFormBlock .hs_interested_in label:first-child,
.valdHsFormBlock .hs_vald_newsletter label:first-child {
  color: #ff7a00;
  font-size: 20px;
  font-weight: 500;
}

.valdHsFormBlock .hs_interested_in div label:first-child,
.valdHsFormBlock .hs_vald_newsletter div label:first-child {
  color: #25282a;
  font-size: 16px;
  font-weight: 400;
}

.valdHsFormBlock .form-columns-1 ul label:first-child span {
  margin-top: -3px;
  line-height: 22px;
}

.valdHsFormBlock .hs_interested_in .inputs-list {
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, auto);
  grid-column-gap: 16px;
  grid-row-gap: 20px;
}

.valdHsFormBlock .inputs-list li label {
  display: flex;
  align-items: start;
  gap: 16px;
}

/* vald newsletter */
.valdHsFormBlock .hs_vald_newsletter .inputs-list {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(auto-fill, auto);
  grid-row-gap: 16px;
}

/* Message */
.valdHsFormBlock .hs_your_message input {
  width: 100%;
}

.valdHsFormBlock .hs_your_message label {
  color: #ff7a00;
  font-size: 20px;
  font-weight: 700;
  padding-top: 15px;
}

.valdHsFormBlock .hs_your_message textarea {
  padding: 15px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
  color: #25282a;
  background-color: #f6f6f6;
  width: 100% !important;
  height: 180px !important;
}

/* Captcha  */
.valdHsFormBlock .hs_recaptcha {
  padding-top: 25px;
}

/* submit button */
.valdHsFormBlock .hs_submit input {
  padding: 12px 22px !important;
  border-radius: 6px !important;
  border: 0 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.valdHsFormBlock .hs_submit input {
  background-color: #ff7b00 !important;
  color: white;
}

.valdHsFormBlock .hs_submit input:hover {
  background-color: #ff9a3d !important;
  color: #ffffff !important;
}
.valdHsFormBlock .hs_submit .actions {
  padding-top: 2.4rem;
}

/* Break points */
@media only screen and (max-width: 767px) {
  .valdHsFormBlock .form-columns-2 {
    display: flex;
    flex-direction: column;
  }

  .valdHsFormBlock .form-columns-2 div {
    width: 100% !important;
  }

  .valdHsFormBlock .hs_interested_in .inputs-list {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(auto-fill, auto);
  }
}

/* Douglas's codes */
.valdHsFormBlock .inputs-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.valdHsFormBlock .inputs-list li {
  line-height: 1.2;
  padding: 0;
  margin-left: 0;
  margin-bottom: 1rem !important;
}
#top label {
  font-weight: bold;
  font-size: 0.92em;
}
.valdHsFormBlock input[type='radio'] {
  visibility: visible !important;
  margin: 0 5px !important;
  width: auto !important;
  position: relative;
  top: 2px;
}
input[type='radio'] {
  accent-color: #ff7a00 !important;
  outline: none;
}
.valdHsFormBlock .radio-bts .inputs-list li label {
  gap: 0;
}

/* ================   SMART SPEED TRADE-IN PROGRAM HUBSPOT FORM  ==================*/
.SSPTradeIn li.hs-form-checkbox,
.SSPTradeIn li.hs-form-radio {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid;
  border-radius: 4px;
  min-width: 75px;
  text-align: center;
}

.SSPTradeIn li.hs-form-checkbox label,
.SSPTradeIn li.hs-form-radio label {
  display: block;
  padding: 10px;
  cursor: pointer;
}

.SSPTradeIn li.hs-form-checkbox input,
.SSPTradeIn li.hs-form-radio input {
  display: none;
}

.SSPTradeIn li.hs-form-checkbox.active,
.SSPTradeIn li.hs-form-radio.active {
  background-color: #ff7a00;
  border-color: #ff7a00;
}

.SSPTradeIn li.hs-form-checkbox.active span,
.SSPTradeIn li.hs-form-radio.active label span {
  color: #fff !important;
}

.SSPTradeIn .hs_email span,
.SSPTradeIn .hs_current_used_timing_gates___other > label > span,
.SSPTradeIn .hs_current_amount_of_timing_gates_owned > label > span,
.SSPTradeIn .hs_current_used_timing_gates > label > span {
  font-size: 22px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 1rem !important;
}

.SSPTradeIn .hs-field-desc {
  font-size: 14px !important;
  margin-bottom: 2rem;
}

.SSPTradeIn .hs_email,
.SSPTradeIn .hs_current_used_timing_gates___other,
.SSPTradeIn .hs_current_amount_of_timing_gates_owned,
.SSPTradeIn .hs_current_used_timing_gates {
  margin-bottom: 1.5rem;
}

.SSPTradeIn .hs_submit input {
  padding: 12px 22px !important;
  border-radius: 6px !important;
  border: 0 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.SSPTradeIn .hs_submit input {
  background-color: #ff7b00 !important;
  color: white;
}

.SSPTradeIn .hs_submit input:hover {
  background-color: #ff7b00 !important;
  color: #25282a;
}

.SSPTradeIn .hs_submit .actions {
  padding-top: 2.4rem;
}

.SSPTradeIn .hs_recaptcha.hs-recaptcha.field.hs-form-field {
  margin-bottom: 1rem;
}

.SSPTradeIn .hs_email input,
.SSPTradeIn .hs-fieldtype-text input {
  width: 100%;
  background-color: #f6f6f6;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 5px;
}

.SSPTradeIn .hs-error-msgs label {
  color: #ce4956;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-left: 7px !important;
}

/* BDMs popup Hubspot stylings */

.bdm-popup .hs_country__temp_ {
  display: none;
}
.bdm-popup fieldset:has(.hs-country__temp_) {
  padding: 0;
}
.bdm-popup .valdHsFormBlock fieldset label {
  color: #777777;
  font-size: 1.5rem;
  font-weight: 400;
}
.bdm-popup .valdHsFormBlock fieldset input.hs-input::placeholder,
.bdm-popup .valdHsFormBlock fieldset .hs-fieldtype-textarea::placeholder {
  color: #a2a2a2;
  font-size: 1.38rem;
}
.bdm-popup .valdHsFormBlock fieldset input.hs-input {
  background-color: #f6f6f6;
  height: 3.6rem;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
}
.bdm-popup .valdHsFormBlock fieldset textarea.hs-fieldtype-textarea {
  padding: 0.5rem 1rem;
}
.bdm-popup .valdHsFormBlock fieldset .input {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.bdm-popup .valdHsFormBlock fieldset .hs-dependent-field .hs-fieldtype-select .input select {
  font-size: 1.3rem;
  height: 3.6rem;
  line-height: 2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.bdm-popup .valdHsFormBlock fieldset .hs_your_message .input textarea {
  height: 8.4rem !important;
  border-radius: 0.5rem;
  margin: 0;
}
.hs_recaptcha.hs-recaptcha.field.hs-form-field {
  opacity: 0;
  height: 0;
  padding: 0;
  z-index: -1;
  position: absolute;
}
.bdm-popup .hs-submit .actions {
  text-align: right;
  z-index: 2;
}
.bdm-popup .hs-submit .actions input {
  padding: 0.5rem 2rem !important;
}
.bdm-popup .submitted-message {
  padding: 4rem 0;
}
.bdm-popup .submitted-message h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  color: #ff7a00;
}
.bdm-popup .submitted-message p {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 300;
  margin: 2rem 0;
  color: #393e41;
}

/* Ensure all checkboxes have the same size and appearance */
.hs-form-checkbox input[type='checkbox'] {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  min-height: 18px !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

/* When checked, show the check mark */
.hs-form-checkbox input[type='checkbox']:checked {
  background-color: #ff7a00;
  border-color: #ff7a00;
}

/* Create the check mark using ::after */
.hs-form-checkbox input[type='checkbox']:checked::after {
  content: '✓'; /* Unicode check mark */
  font-size: 14px;
  color: white;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

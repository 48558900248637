.gridContainer {
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-template-rows: repeat(3, auto); /* 3 rows */
  grid-auto-flow: column; /* Fill columns first */
}

.bubble::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 17px;
  height: 20px;
  background-color: #ababab;
  clip-path: polygon(0 0, 0 100%, 190% 0);
  z-index: -1;
}
